//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
export default {
  name: 'SharedEntity',
  props: ['id'],
  created() {
    this.fetchEntity(this.id);
  },
  data() {
    return {
      isLoading: false,
      entity: null,
      isNotFound: false,
      isForbidden: false
    }
  },
  filters: {
    filterEventDate: function (date,date_end) {
      return date_end == date ? moment(new Date(date)).format('MMMM DD, YYYY') :   moment(new Date(date)).format('MMMM DD, YYYY') + ' - ' + moment(new Date(date_end)).format('MMMM DD, YYYY')
    },
  },
  methods: {
    gotoEntity() {
      switch (this.entity.type) {
        case 'Event':
          this.$router.push({name: 'Event', params: {id: this.entity.id}})
        break;
        case 'Group':
          this.$router.push({name: 'Group', params: {id: this.entity.id}})
        break;
      }
    },
    fetchEntity(id) {
      this.isLoading = true;
      let entity = this.$store.getters['entities/read'](id);
      if (entity !== undefined) {
        this.entity = entity;
        this.isLoading = false;
        return;
      }
      this.$http({
        url: 'posts/entity?id=' + id,
        method: 'GET'
      }).then((response) => {
        this.entity = response.data['body'];
        this.$store.commit('entities/save', {
          id: id,
          name: this.entity.name,
          owner: this.entity.owner,
          type: this.entity.type,
          membersCount: this.entity.membersCount,
          date_start: this.entity.date_start ?? null,
          date_end: this.entity.date_end ?? null,
          venue: this.entity.venue ?? null,
          photo: this.entity.photo ?? null
        });
        this.isLoading = false;
      }).catch((err) => {
        this.isLoading = false;
        switch (err.response.status) {
          case 404:
            this.isNotFound = true;
          break;
          case 403:
            this.isForbidden = true;
          break;
          case 500:
            this.isNotFound = true;            
          break;
        }
      })
    }
  }
}
